























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IStrDetailValue, IInfo } from "@/Services/Interfaces";
import {
  globalUpdateDetailService,
  globalInfoService
} from "@/Services/GlobalService";
import { InfoType, CrudType } from "@/Services/enums";

import BaseSubComponent from "./BaseSubComponent.vue";
import ActionComponent from "./ActionComponent.vue";

@Component({ components: { ActionComponent, BaseSubComponent } })
export default class DateComponent extends BaseSubComponent {
  startdate: string;
  isodate: string;
  vis: boolean;

  constructor() {
    super();
    this.name = "DateComponent";
    this.vis = false;
    this.thismodel = <IStrDetailValue>{};
    this.startdate = this.parseDate(this.thismodel.strvalue)
      .toISOString()
      .substr(0, 10);
    this.isodate = this.parseDate(this.thismodel.strvalue)
      .toISOString()
      .substr(0, 10);
  }

  thismodel: IStrDetailValue;
  name: string;

  @Watch("isodate")
  onPropertyChanged(value: any) {
    if (value != undefined) {
      this.thismodel.strvalue = this.formatDate(new Date(value));
      this.onUpdate(this.thismodel);
      this.vis = false;
    }
  }
  /** */

  formatDate(date: Date): string {
    try {
      var m = date.getMonth() + 1;
      var d =
        date
          .getDate()
          .toString()
          .padStart(2, "0") +
        "." +
        m.toString().padStart(2, "0") +
        "." +
        date.getFullYear();

      return d;
    } catch (e) {
      return "";
    }
  }

  parseDate(date: string): Date {
    try {
      if (!date) return new Date();
      const [day, month, year] = date.split(".");
      var d = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

      return new Date(d);
    } catch (e) {
      return new Date();
    }
  }

  onblurUpdate(model: IStrDetailValue) {
    try {
      var d = this.parseDate(model.strvalue);
      this.isodate = d.toISOString().substr(0, 10);

      this.onUpdate(model);
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Datum irgendwie falsch " + model.strvalue,
        error: e
      });
    }
  }

  onUpdate(model: IStrDetailValue) {
    model.crud = CrudType.updatestrvalue;
    globalUpdateDetailService.sendMessage(model);
  }

  data() {
    return {
      vis: false,
      thismodel: this.model,
      canAction: false,
      isodate: (this.model ? this.parseDate(this.model.strvalue)
        .toISOString()
        .substr(0, 10) // string puh
        : null)
    } ;
  }

  computedDateFormatted() {
    return this.formatDate(new Date(this.isodate));
  }

  mounted() {}
}
